@import url("https://fonts.googleapis.com/css2?family=Playwrite+CU:wght@100..400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.mobile-header{
  display: none;
}
.main {
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0px;
  margin: 0px;
}
html, body{
  max-width: 100%;
  overflow-x: hidden;
  background-color: black;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.main2, .main3{
  display: none;
}
.main1 {
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
 
}
.header{
  position: absolute;
  width: 100vw;
  height: 100px;
 
  top: 0px;
  display: flex;
  overflow-x: hidden;
}
.header-side{
  width: 50vw;
  height: 100%;
  display: flex;
}
.title-holder{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.title{
  font-size: 40px;
  color: white;
  margin-left: 100px;
  z-index: 2;
}
.title:hover{
  cursor: pointer;
}
.header-right{
  width: 50vw;
  height: 100%;
  background-color: #FC6AFF;
  border-radius: 0px 0px 0px 20px;
  display: flex;
  z-index: 1;
}
.header-third{
  width: 33vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-button{
  color: white;
  font-size: 20px;
  transition: 0.5s;
  z-index: 2;
}
.header-button:hover{
  font-size: 22px;
  cursor: pointer;
}
.main-side{
  width: 50vw;
  height: 100vh;
 
  padding: 0;
  margin: 0;
  position: relative;
}

.svg{
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 width: 40vw;
 height: 40vw;
}
.profilephoto{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -50%);
  width: 35vw;
  height: 20vw;
  animation-name: fadein;
  animation-duration: 4s;
 
}
.profilephotosuit{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -50%);
  width: 20vw;
  height: 30vw;
 
}
.main-text-center{
  width: 50vw;
  height: 500px;
  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.main-text-center1{
  width: 40vw;
  height: 500px;
  
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.main-text-header{
  margin-top: 100px;
  color: white;
  font-size: 40px;
  z-index: 1;
}
.main-text-details{
  font-size: 1.5vw;
  color: white;
 
}
.main-text-additional-details{
  font-size: 1.2vw;
  color: rgba(255, 255, 255, 0.596);
  width: 80%;
 
}
.main-text-additional-details1{
  font-size: 1.2vw;
  color: white;
  width: 80%;
 
}
.footer{
  width: 100vw;

  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.footer-center{
  width: 50vw;
  height: 15vh;
  
  display: flex;
}
.footer-third{
  width: 33vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer-third-half{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
 
}
.footer-header-text{
  color: white;
  font-size: 18px;
  font-weight: 500;
}
.footer-info-text{
  color: white;
  font-size: 16px;
  
}
a{
  text-decoration: none;
}
.portfolio-main{
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.portfolio-header{
  width: 100vw;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: black;
}
.portfolio-header-text{
  color: white;
  font-size: 50px;
}
.grid{
  
  margin-top: 50px;
  width: 80vw;
  
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 10px; /* Space between items */
  justify-items: center; /* Center items horizontally within each cell */
  align-items: center; /* Center items vertically within each cell */

 
}
.project-card{
  position: relative;
  width: 90%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  background-color: gray;
  background-size: cover; /* This makes the background image cover the entire area */
  background-repeat: no-repeat; /* This prevents the image from repeating */
  background-position: center; /* This centers the image */
  transition: 0.5s;
}
.project-card:hover{
  cursor: pointer;
  transform: scale(1.05);
}
.project-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(97, 97, 97, 0.633); /* Gray color with 50% opacity */
  pointer-events: none; /* Allow clicks to pass through */
}

.project-card-title{
  font-size: 40px;
  color: white;
  z-index: 2;
  text-align: center;
  width: 70%;
}

.project-header{
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: black;
  padding-left: 200px;
  flex-direction: column;
}
.project-header-title{
  color: white;
  font-size: 50px;
  margin-top: 200px;
  text-align: center;
}
.project-section-header{
  color: white;
  font-size: 30px;
  margin-top: 25px;
}
.project-section-info{
  color: white;
  font-size: 20px;
  margin-top: 0px;
  width: 40vw;
  font-weight: 300;
}
.project-image-holder{
  width: 100vw;
  height: 100%;
 
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-grid-holder{
  width: calc(100vw - 200px);
  height: 100%;
  margin-top: 100px;
  
  display: flex;
}
.project-grid-side{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.project-grid-side1{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
}
.project_img{
  width: 90%;
  margin-bottom: 100px;
 
  border-radius: 8px;
}
iframe{
  width: 80vw;
  height: 50vw;
}

@keyframes fadein {
  0% {transform: scale(0) translate(-45%, -50%);}
  75% {transform: scale(0) translate(-45%, -50%);}
  100% {transform: scale(1) translate(-45%, -50%);}
}
@media (max-width : 700px), (pointer:none), (pointer:coarse) {
  .main1{
    display: none;
  }
  .main3{
    width: 100vw;
    height: 80vh;
    
    display: flex;
    overflow: hidden;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    padding: 0px;
    margin: 0px;
    overflow-x: hidden;
    flex-direction: column;
    padding-top: 20vh;
  }
  .mobile-header{
    width: 100%;
    height: 100px;
    
    position: absolute;
    top: 0;
    display: flex;
    
  }
  .mobile-header-half{
    width: 70%;
    height: 100%;
  
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .mobile-header-half1{
    width: 30%;
    height: 100%;
  
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .mobile-header-title{
    color: white;
    font-size: 25px;
  }
  .mobile-header-ham{
    width: 40px;
    margin-right: 50px;
   
  }
  .mobile-menu{
    width: 100vw;
    height: 100vh;
    background-color: black;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0px;
    z-index: 5;
  }
  .menu-item-holder{
  
    width: 80vw;
    height: 300px;
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

  }
  .menu-item{
    color: white;
    border-bottom: 1px solid white;
   
    width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    font-size: 20px;
    opacity: 0;
    animation: fadeInText 1s forwards;
  }
  .header{
    display: none;
  }
  
  
  .menu-item:nth-child(1) {
    animation-delay: 0s; /* Adjust delay as needed */
  }
  
  .menu-item:nth-child(2) {
    animation-delay: 0.5s; /* Adjust delay as needed */
  }
  
  .menu-item:nth-child(3) {
    animation-delay: 1s; /* Adjust delay as needed */
  }
  .grid{
  
    margin-top: 50px;
    width: 80vw;
    
    height: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* 3 columns */
    gap: 10px; /* Space between items */
    justify-items: center; /* Center items horizontally within each cell */
    align-items: center; /* Center items vertically within each cell */
  
   
  }
  .footer{
    width: 100vw;
  
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: black;
    flex-direction: column;
  }
  .footer-center{
    width: 100vw;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    
    align-items: center;
  
  }
  .footer-third{
    width: 50%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin-bottom: 50px;
    
  }
  .project-header{
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
   
    flex-direction: column;
    margin-left: 0px;
    padding-left: 0px;
  }
  .project-section-info{
    width: 80%;
    text-align: center;
  }
  .project-grid-holder{
    width: 90%;
    height: 100%;
    margin-top: 100px;
    
    display: flex;
    flex-direction: column;
  }
  .project-grid-side, .project-grid-side1{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .vid{
    margin: auto;
  }.main2 {
    margin-top: 10vh;
    width: 100vw;
    height: 90vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    padding: 0px;
    
    overflow-x: hidden;
   
  }
  .main2-side{
    width: 100vw;
    height: 50vh;
   
    padding: 0;
    margin: 0;
    
  }
  .main-text-center2{
    
    width: 100vw;
    height: 50vh;
    
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .main-text-header{
    
    text-align: center;
  }
  .main-text-additional-details1{
    text-align: center;
    font-size: 4vw;
    width: 90%;
  }
  .profilephotosuit{
    position: relative;
    width: 20vw;
    height: 30vw;
   
  }
  .main-side{
   
      width: 100vw;
      height: 50vh;
     
     display: flex;
     justify-content: center;
     align-items: center;
      position: relative;
      
  }
  .main-text-center{
    width: 100vw;
    height: 50%;
    
   position: relative;
    display: flex;
    justify-content: flex-end;
   
    flex-direction: column;
    align-items: center;
  }
  .main-text-header2{
    margin-top: 100px;
    color: white;
    font-size: 8vw;
    
  }
  .main-text-details2{
    font-size: 5vw;
    color: white;
    width: 90%;
    text-align: center;
   
  }
  .main-text-additional-details2{
    font-size: 4vw;
    color: rgba(255, 255, 255, 0.596);
    width: 80%;
    text-align: center;
    
  }
  .svg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    height: 60vw;
   }
   .profilephoto{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
    width: 52vw;
    height: 30vw;
    animation-name: fadein;
    animation-duration: 4s;
   
  }
}

@keyframes fadeInText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
  
}
